<template>
    <v-main>
        <div class="container">
            <div class="d-flex align-center" flat>
                <v-card min-width="320px" max-width="380px">
                    <v-card-title>
                        <h4 class="av-card-title">Sign In</h4>
                    </v-card-title>

                    <v-card-subtitle>
                        Don't have an account?
                        <router-link :to="{ name: 'Login' }"
                            >Sign Up</router-link
                        >
                    </v-card-subtitle>

                    <v-card-text>
                        <ValidationObserver ref="observer" v-slot="{}">
                            <v-form @submit.prevent="login(email, password)">
                                <v-row dense>
                                    <v-col>
                                        <CustomLabel
                                            for="email"
                                            label="Email"
                                        />
                                        <CustomTextField
                                            id="email"
                                            inputPlaceholder="Email"
                                            :inputValue="email"
                                            validationName="email"
                                            validationRules="required"
                                            @input="
                                                updateLocalForm($event, 'email')
                                            "
                                        />
                                    </v-col>
                                </v-row>

                                <v-row dense>
                                    <v-col>
                                        <CustomLabel
                                            for="password"
                                            label="Password"
                                        />
                                        <CustomTextField
                                            id="password"
                                            inputPlaceholder="Password"
                                            inputType="password"
                                            :inputValue="password"
                                            validationName="password"
                                            validationRules="required"
                                            @input="
                                                updateLocalForm(
                                                    $event,
                                                    'password'
                                                )
                                            "
                                        />
                                    </v-col>
                                </v-row>

                                <v-row dense>
                                    <v-col
                                        ><v-checkbox dense label="Remember me"
                                    /></v-col>
                                </v-row>

                                <v-row dense>
                                    <v-col>
                                        <CustomButton
                                            block
                                            btnColour="primary"
                                            :btnIsLoading="loginOps.isLoading"
                                            btnLabel="Sign In"
                                            tooltipMsg="Sign In"
                                            type="submit"
                                        />
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col>
                                        <v-divider />
                                    </v-col>
                                </v-row>

                                <v-row dense>
                                    <v-col>
                                        <GoogleIdentity />
                                    </v-col>
                                </v-row>
                            </v-form>
                        </ValidationObserver>
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </v-main>
</template>

<script>
import { mapState } from "vuex";
// vee-validate utilities
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", {
    ...required,
    message: "Field cannot be empty.",
});

export default {
    components: {
        GoogleIdentity: () => import("@/components/utils/GoogleIdentity"),
    },
    computed: {
        ...mapState("authsMgmt", ["loginOps"]),
    },
    data: () => ({
        view: {
            password: true,
        },
        // email: "Jesse.Randall@acme.com",
        // password: "J3RZH9I7wzhOXEFV6acRstebmUDRVAJf",
        email: null,
        password: null,
    }),
    methods: {},
    name: "Login",
    // Lifecycle hooks.
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>
